/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  'use strict';

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function() {
        // JavaScript to be fired on all pages

        /**
         * Header animation with a RAF debounce
         * https://www.html5rocks.com/en/tutorials/speed/animations/
         */
        var lastKnownScrollY = 0;
        var lastUpdatedScrollY = 0;
        var scrollCheck = false;
        var showingHeader = false;

        function updateHeaderPosition() {
          var currentScrollY = lastKnownScrollY;
          var $header = $('.banner');
          var headerHeight = $header.outerHeight();
          var headerOffsetTop = $header.position().top;
          var scrollingDown = true;
          var nearTopParallaxFactor = 1.8; // 1 = 1 to 1 scroll, higher numbers => slower

          if (currentScrollY < lastUpdatedScrollY) {
            scrollingDown = false;
          }

          scrollCheck = false;

          // near the top?
          if (currentScrollY / nearTopParallaxFactor < headerHeight) {
            // if we are near to the top...
            var newPositionY = currentScrollY / nearTopParallaxFactor;
            if (currentScrollY === 0 || !showingHeader) {
              //...and we are either at the very top of the page OR not already showing the header...
              // ...parallax the header
              $header.removeClass('has-transition');
              showingHeader = false;
              $header.css('transform', 'translateY(-' + newPositionY + 'px)');
            }
          } else {
            // we are not near the top so...
            $header.addClass('has-transition');
            if (scrollingDown) {
              // ...if scrolling down, hide the header
              showingHeader = false;
              $header.css('transform', 'translateY(-' + headerHeight + 'px)');
            } else {
              // ...if scrolling up, show the header
              showingHeader = true;
              $header.css('transform', 'translateY(0)');
            }
          }

          // update globals
          lastUpdatedScrollY = currentScrollY;
        }

        function onScroll() {
          lastKnownScrollY = window.pageYOffset;
          if (!scrollCheck) {
            requestAnimationFrame(updateHeaderPosition);
          }
          scrollCheck = true;
        }
        window.addEventListener('scroll', onScroll, false);

        /* Main menu toggle */
        /* https://codepen.io/svinkle/pen/LDKwA */
        var header = document.querySelector('.banner .container');
        var menu = document.querySelector('.nav-primary');
        var main = document.querySelector('.wrap');
        var footer = document.querySelector('.content-info');
        var menuToggle = document.createElement('a');

        function hideMobileMenu() {
          menu.setAttribute('aria-hidden', 'true');
          main.setAttribute('aria-hidden', 'false');
          footer.setAttribute('aria-hidden', 'false');
          menuToggle.setAttribute('aria-expanded', 'false');
        }

        function showMobileMenu() {
          menu.setAttribute('aria-hidden', 'false');
          main.setAttribute('aria-hidden', 'true');
          footer.setAttribute('aria-hidden', 'true');
          menuToggle.setAttribute('aria-expanded', 'true');
        }

        function isHidden(element) {
          var style = window.getComputedStyle(element);
          return style.display === 'none';
        }

        function isMobile() {
          // since our MQs are in ems, instead of checking for the screen width we will instead look to see if the mobile menu toggle is visible
          return !isHidden(menuToggle);
        }

        function checkMobileMenu() {
          if (!isMobile()) {
            if (isHidden(main)) {
              // if main is hidden (as when the mobile menu is toggled) then disable the mobile menu
              hideMobileMenu();
            }
          }
        }

        function initMenu() {
          // button properties
          menuToggle.classList.add('nav-toggle');
          menuToggle.setAttribute('href', '#nav-primary');
          menuToggle.setAttribute('id', 'nav-toggle');
          menuToggle.setAttribute('aria-label', 'Menu');
          menuToggle.setAttribute('aria-expanded', 'false');
          menuToggle.setAttribute('aria-controls', 'nav-primary');
          menuToggle.innerHTML =
            '<span></span><span></span><span></span><span></span><div class="screen-reader-text">Show the menu</div>';

          // menu properties
          menu.setAttribute('aria-hidden', 'true');
          menu.setAttribute('aria-labeledby', 'nav-toggle');

          // add toggle button to page
          header.insertBefore(menuToggle, menu);

          // handle button click event
          menuToggle.addEventListener(
            'click',
            function(e) {
              e.preventDefault();

              if (menu.hasAttribute('aria-hidden') && menu.getAttribute('aria-hidden') === 'false') {
                hideMobileMenu();
              } else {
                showMobileMenu();
              }
            },
            false
          );

          // check for desktop sizing to disble mobile menu
          setInterval(checkMobileMenu, 180);
        }

        // setup the mobile menu and menu toggle button
        initMenu();

        /**
         *  scroll reveal
         */
        window.sr = new ScrollReveal({ delay: 250, opacity: 0, scale: 1, reset: false, duration: 1000 });
        sr.reveal('[class^="section"] > *');

        /**
         * Flickity: Join slideshow
         */
        if ($('.section-slideshow-join .block-wrap').length > 0) {
          var $joinSlider = $('.section-slideshow-join .block-wrap');
          var joinSliderOptions = {
            pageDots: false,
            wrapAround: true,
            imagesLoaded: true
          };

          if (isMobile()) {
            joinSliderOptions.draggable = '>1';
          } else {
            joinSliderOptions.draggable = false;
          }

          $joinSlider.flickity(joinSliderOptions);
        }

        /**
         * Flickity: Work slideshow
         */
        if ($('.section-slideshow-work .block-wrap').length > 0) {
          var $workSlider = $('.section-slideshow-work .block-wrap');
          var workSliderOptions = {
            pageDots: false,
            wrapAround: true,
            imagesLoaded: true,
            adaptiveHeight: true // just for older browsers
          };

          if (isMobile()) {
            workSliderOptions.draggable = '>1';
          } else {
            workSliderOptions.draggable = false;
          }

          $workSlider.flickity(workSliderOptions);

          var updateProgressBar = function(index) {
            var slideCount = $workSlider.find('.block').length;
            var $progressBar = $('.slide-progress span');
            $progressBar.width((index + 1) / slideCount * 100 + '%');
          };

          updateProgressBar(0);

          $workSlider.on('change.flickity', function(event, index) {
            updateProgressBar(index);
          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
